import { token } from "../utils";
import mystore, { vid } from "./appStore";

document.socket = {
	skt: null,
	failureCount: 0,
	reconnectingInterval: 1000,
	storeDispatchHistory: [],
	isUpdate: true,
	init: function () {
		this.skt = new WebSocket(
			`wss://api.autovid.ai/ws/app/video/?token=${token}`
		);
		this.skt.addEventListener("message", this.message);
		this.skt.addEventListener("open", this.open);
		this.skt.addEventListener("error", this.err);
		this.skt.onclose = (e) => {
			this.failureCount += 1;

			setTimeout(function () {
				document.socket.init();
			}, 1000);
		};
	},

	restoreHistory: function () {
		document.socket.storeDispatchHistory.forEach((_dispatchData) => {
			mystore.dispatch(_dispatchData)
		})
		document.socket.storeDispatchHistory = [];
	},
	message: function (mess) {
		const _vaildType = { "download_history": 1, "solo_history": 1, "solomail_history": 1, "batch_history": 1 };
		let _dispatchData = null;
		if (mess.data) {
			let _data = JSON.parse(mess.data);
			const _command = _data.command;
			if (_command === 'sharingPage.remove') {
				// remove from store
				if (_data?.data?.id) {
					_dispatchData = {
						type: "DELETE_MEDIA",
						data: {
							id: _data?.data?.id,
							// detail: data.data.result,
							type: "salespage",
						},
					};
				}
			} else if (_command === 'sharingPage.add') {
				// add on top
				// remove from store
				if (_data?.data?.id) {
					_dispatchData = {
						type: "ADD_SINGLE_MEDIA",
						data: {
							result: _data?.data,
							type: "salespage",
						},
					};
				}
			} else if (_command === 'imageEditor.add') {
				// add on top
				// remove from store
				if (_data?.data?.id) {
					_dispatchData = {
						type: "ADD_SINGLE_MEDIA",
						data: {
							result: _data?.data,
							type: "iimage",
						},
					};
				}
			} else if (_command === 'imageEditor.remove') {
				// remove from store
				if (_data?.data?.id) {
					_dispatchData = {
						type: "DELETE_MEDIA",
						data: {
							id: _data?.data?.id,
							// detail: data.data.result,
							type: "iimage",
						},
					};
				}
			} else if (_command === 'videoEditor.details.update') {
				// remove from store
				if (Number(_data?.data?.id) === Number(vid()) && _data?.data?.results) {
					_dispatchData = {
						type: "EDIT_DETAIL",
						data: {
							..._data?.data?.results
						},
					};
				}
			} else if (_command === 'videoEditor.progress.update') {
				// remove from store
				if (Number(_data?.data?.id) === Number(vid()) && _data?.data?.results) {
					const _response = _data?.data?.results;
					if (_response.type === 0) {
						// main video
						let _mainVideoGenerate = mystore.getState()?.mydetail?.mainVideoGenerate;
						if (_mainVideoGenerate) {
							_mainVideoGenerate = { ..._mainVideoGenerate, progress: _response.progress, status: _response.status }
							if (_response.video) {
								_mainVideoGenerate.video = _response.video;
							}
						}
						_dispatchData = {
							type: "EDIT_DETAIL",
							data: {
								mainVideoGenerate: _mainVideoGenerate
							},
						};
					} else if (_response.type === 1 || _response.type === 2 || _response.type === 4 || _response.type === 3) {
						// download_history
						const _map = { 1: "download_history", 2: "solo_history", 4: "solomail_history", 3: "batch_history" };
						let _edit = { progress: _response.progress, status: _response.status };
						if (_response.video) {
							_edit.video = _response.video;
						}
						if (_response.campaignUrl) {
							_edit.campaignUrl = _response.campaignUrl;
						}
						if (_response.type === 3) {
							_edit.generatedCount = _response.generatedCount
							_edit.totalCount = _response.totalCount
						}
						_dispatchData = {
							type: "EDIT_MEDIA",
							data: {
								id: _response.id,
								edit: _edit,
								type: _map[_response.type],
							},
						};
					}
				}
			} else if (_command === 'videoEditor.list.add') {
				// add to generated
				if (_data?.data?.id) {
					mystore.dispatch({
						type: "ADD_SINGLE_MEDIA",
						data: {
							result: _data?.data,
							type: 'generated',
							addIfPresent: true,
						},
					});
					mystore.dispatch({
						type: "DELETE_MEDIA",
						data: {
							id: _data?.data?.id,
							type: 'draft',
						},
					});
				}
			} else if (_command === 'videoEditor.list.remove') {
				// remove from draft as well as generated (isGenerated)
				const _type = _data?.data?.isGenerated ? 'generated' : 'draft'
				if (_data?.data?.id) {
					_dispatchData = {
						type: "DELETE_MEDIA",
						data: {
							id: _data?.data?.id,
							type: _type,
						},
					};
				}
			} else if (_command === 'videoEditor.progress.add' && Number(_data?.data?.id) === Number(vid()) && _data?.data?.results && Number(_data?.data?.id) === Number(vid()) && _vaildType[_data?.data?.type]) {
				_dispatchData = {
					type: "ADD_SINGLE_MEDIA",
					data: {
						result: _data.data.results,
						type: _data?.data?.type,
					},
				};

			}

		}
		if (_dispatchData) {

			if (document.socket.isUpdate) {
				mystore.dispatch(_dispatchData);
			} else {
				document.socket.storeDispatchHistory.push(_dispatchData);
			}
		}

	},
	open: function (data) {
		this.failureCount = 0;
		this.reconnectingInterval = 1000;

	},
	err: function (err) {
		console.log(err);
	},
};

document.socket.init();
